<nb-card
  size="tiny"
  class="hive-api-card-monitor"
  (click)="openDetail(12)"
>
  <nb-card-header>
    <img [src]="logo" >
    <span class="title">{{ client }}</span>
  </nb-card-header>
  <nb-card-body>
    <h1>{{ service }}</h1>
  </nb-card-body>
  <nb-card-footer>
    <H6>Custo</H6>
    <div class="detail">{{ value }} / requisição</div>
  </nb-card-footer>
</nb-card>
