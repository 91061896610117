/**
 * @license
 * Copyright HIVEPlace. All Rights Reserved.
 * License private
 */
import { Component } from '@angular/core';

@Component({
  selector: 'nb-auth-block',
  template: '<ng-content></ng-content>',
  styleUrls: ['./_auth-block.component.theme.scss', './auth-block.component.scss'],
})

export class NbAuthBlockComponent {
}
