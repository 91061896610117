export class NbUser {
    id?: number;
    email?: string;
    password?: string;
    rememberMe?: boolean;
    terms?: boolean;
    confirmPassword?: string;
    fullName?: string;
    cnpj?: string;
    cpf?: string;
    idSegment?: string;
    txTradingName?: string;
    txNameOrganisation?: string;
    txUrl?: string;
    txTopicNamePn?: string;
    cdBank?: string;
    userNameAdmin?: string;
    txFirstName?: string;
    txLastName?: string;

    constructor(id?: number,
      email?: string,
      password?: string,
      rememberMe?: boolean,
      terms?: boolean,
      confirmPassword?: string,
      fullName?: string,
      cnpj?: string,
      cpf?: string,
      idSegment?: string,
      txTradingName?: string,
      txNameOrganisation?: string,
      txUrl?: string,
      txTopicNamePn?: string,
      cdBank?: string,
      userNameAdmin?: string,
      txFirstName?: string,
      txLastName?: string,
    ) {
      this.id = id;
      this.email = email;
      this.password = password;
      this.rememberMe = rememberMe;
      this.terms = terms;
      this.confirmPassword = confirmPassword;
      this.fullName = fullName;
      this.cnpj = cnpj;
      this.cpf = cpf;
      this.idSegment = idSegment;
      this.txTradingName = txTradingName;
      this.txNameOrganisation = txNameOrganisation;
      this.txUrl = txUrl;
      this.txTopicNamePn = txTopicNamePn;
      this.cdBank = cdBank;
      this.userNameAdmin = userNameAdmin;
      this.txFirstName = txFirstName;
      this.txLastName = txLastName;
    }
}
