import { Component } from '@angular/core';

@Component({
  selector: 'hive-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      © 2019 promote. All Rights Reserved | Design by <a href="https://www.hiveplace.com.br" target="_blank"> HIVEPlace</a>
    </span>
  `,
})
export class FooterComponent {
}
