<nb-card
  size="tiny"
  class="hive-api-card-monitor"
  [class]="status"
>
  <nb-card-header>
    <img src="assets/images/logo-bb.png" >
    <span class="title">{{ title }}</span>
    <span class="detail">{{ type }}</span>
    <span class="status" [class]="status">{{ statusName ? statusName : status }}</span>
  </nb-card-header>
  <nb-card-body>
    <h1>{{ milliseconds }} ms</h1>
    <div class="detail">{{ timeout }} timeout</div>
  </nb-card-body>
  <nb-card-footer>
    <H6>Último request</H6>
    <div class="detail">{{ request }} horas</div>
  </nb-card-footer>
</nb-card>
