/**
 * @license
 * Copyright HIVEPlace. All Rights Reserved.
 * License private
 */
import { Component, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { NbAuthService } from '../services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'nb-auth',
  template: `
    <nb-layout>
      <nb-layout-column>
        <router-outlet></router-outlet>
      </nb-layout-column>
    </nb-layout>
  `,
  // template: `
  //   <nb-layout>
  //     <nb-layout-column>
  //       <nb-card>
  //         <nb-card-body>
  //           <nb-auth-block>
  //             <router-outlet></router-outlet>
  //           </nb-auth-block>
  //         </nb-card-body>
  //       </nb-card>
  //     </nb-layout-column>
  //   </nb-layout>
  // `,
  styleUrls: ['./auth.component.scss'],
})

export class NbAuthComponent implements OnDestroy {
  protected auth: NbAuthService;
  protected location: Location;
  private destroy$;
  subscription: any;
  authenticated: boolean;
  token: string;

  constructor(auth: NbAuthService, location: Location) {
    this.auth = auth;
    this.location = location;
    this.destroy$ = new Subject();
    this.authenticated = false;
    this.token = '';
    this.subscription = auth.onAuthenticationChange()
        .pipe(takeUntil(this.destroy$))
        .subscribe((authenticated) => {
        this.authenticated = authenticated;
    });
  }
  back(): any {
    this.location.back();
    return false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
