/**
 * @license
 * Copyright HIVEPlace. All Rights Reserved.
 * License private
 */
import { NbAuthTokenClass, NbAuthSimpleToken } from '../../services/token/token';
import { NbAuthStrategyOptions } from '../auth-strategy-options';
import { getDeepFromObject } from '../../helpers';

export interface NbPasswordStrategyModule {
  alwaysFail?: boolean;
  endpoint?: string;
  method?: string;
  redirect?: {
    success?: string | null;
    failure?: string | null;
  };
  requireValidToken?: boolean;
  defaultErrors?: string[];
  defaultMessages?: string[];
}
export interface NbPasswordStrategyReset extends NbPasswordStrategyModule {
  resetPasswordTokenKey?: string;
}
export interface NbPasswordStrategyToken {
  class?: NbAuthTokenClass;
  key?: string;
  getter?: Function;
}
export interface NbPasswordStrategyMessage {
  key?: string;
  getter?: Function;
}

export class NbPasswordAuthStrategyOptions extends NbAuthStrategyOptions {
  baseEndpoint?: string;
  login?: boolean | NbPasswordStrategyModule;
  register?: boolean | NbPasswordStrategyModule;
  requestPass?: boolean | NbPasswordStrategyModule;
  resetPass?: boolean | NbPasswordStrategyReset;
  logout?: boolean | NbPasswordStrategyReset;
  refreshToken?: boolean | NbPasswordStrategyModule;
  token?: NbPasswordStrategyToken;
  errors?: NbPasswordStrategyMessage;
  messages?: NbPasswordStrategyMessage;
  validation?: {
    password?: {
      required?: boolean;
      minLength?: number | null;
      maxLength?: number | null;
      regexp?: string | null;
    };
    email?: {
      required?: boolean;
      regexp?: string | null;
    };
    fullName?: {
      required?: boolean;
      minLength?: number | null;
      maxLength?: number | null;
      regexp?: string | null;
    };
    cnpj?: {
      required?: boolean,
      minLength?: number | null;
      maxLength?: number | null;
      regexp?: string | null;
    };
  };

  constructor() {
    super();
    this.baseEndpoint = '/api/auth/';

    this.login = {
      alwaysFail: false,
      endpoint: 'login',
      method: 'post',
      requireValidToken: true,
      redirect: {
        success: '/',
        failure: null,
      },
      defaultErrors: ['Login/Email combination is not correct, please try again.'],
      defaultMessages: ['You have been successfully logged in.'],
    };

    this.register = {
      alwaysFail: false,
      endpoint: 'register',
      method: 'post',
      requireValidToken: true,
      redirect: {
        success: '/',
        failure: null,
      },
      defaultErrors: ['Something went wrong, please try again.'],
      defaultMessages: ['You have been successfully registered.'],
    };

    this.requestPass = {
      endpoint: 'request-pass',
      method: 'post',
      redirect: {
        success: '/',
        failure: null,
      },
      defaultErrors: ['Something went wrong, please try again.'],
      defaultMessages: ['Reset password instructions have been sent to your email.'],
    };

    this.resetPass = {
      endpoint: 'reset-pass',
      method: 'put',
      redirect: {
        success: '/',
        failure: null,
      },
      resetPasswordTokenKey: 'reset_password_token',
      defaultErrors: ['Something went wrong, please try again.'],
      defaultMessages: ['Your password has been successfully changed.'],
    };

    this.logout = {
      alwaysFail: false,
      endpoint: 'logout',
      method: 'delete',
      redirect: {
        success: '/',
        failure: null,
      },
      defaultErrors: ['Something went wrong, please try again.'],
      defaultMessages: ['You have been successfully logged out.'],
    };

    this.refreshToken = {
      endpoint: 'refresh-token',
      method: 'post',
      requireValidToken: true,
      redirect: {
        success: null,
        failure: null,
      },
      defaultErrors: ['Something went wrong, please try again.'],
      defaultMessages: ['Your token has been successfully refreshed.'],
    };

    this.token = {
      class: NbAuthSimpleToken,
      key: 'data.token',
      getter: (module, res, options) => getDeepFromObject(res.body, options.token.key, {}),
    };

    this.errors = {
      key: 'data.errors',
      getter: (module, res, options) => getDeepFromObject(res.error, options.errors.key, options[module].defaultErrors),
    };

    this.messages = {
      key: 'data.messages',
      getter: (module, res, options) => getDeepFromObject(res.body, options.messages.key, options[module].defaultMessages),
    };
  }
}

// export declare const passwordStrategyOptions: NbPasswordAuthStrategyOptions;
export const passwordStrategyOptions: NbPasswordAuthStrategyOptions = new NbPasswordAuthStrategyOptions();
