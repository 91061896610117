/**
 * @license
 * Copyright HIVEPlace. All Rights Reserved.
 * License private
 */
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS } from '../../auth.options';
import { getDeepFromObject } from '../../helpers';
import { NbAuthService } from '../../services/auth.service';

@Component({
  selector: 'nb-logout',
  templateUrl: './logout.component.html',
})

export class NbLogoutComponent implements OnInit {
  protected service: NbAuthService;
  protected options: {};
  protected router: Router;
  redirectDelay: number;
  strategy: string;

  constructor(service: NbAuthService, @Inject(NB_AUTH_OPTIONS) options: {}, router: Router) {
    this.service = service;
    this.options = options;
    this.router = router;
    this.redirectDelay = 0;
    this.strategy = '';
    this.redirectDelay = this.getConfigValue('forms.logout.redirectDelay');
    this.strategy = this.getConfigValue('forms.logout.strategy');
  }

  ngOnInit(): void {
    this.logout(this.strategy);
  }

  logout(strategy: string): void {
    this.service.logout(strategy).subscribe((result) => {
      setTimeout(() => {
        return this.router.navigateByUrl('/');
      }, 400);
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
