<nb-card
  size="tiny"
  class="hive-api-card-monitor"
>
  <nb-card-header>
    <span class="title">{{ name }}</span>
    <div class="toggle-item">
      <nb-toggle class="input-group-sm"></nb-toggle>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="img-list">
      <img [src]="logo" alt="">
    </div>
  </nb-card-body>
  <nb-card-footer>
    <p>{{ description }}</p>
  </nb-card-footer>
</nb-card>
