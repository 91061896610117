<h1 id="title" class="title">Esqueceu a senha</h1>
<p class="sub-title">Insira seu endereço de e-mail e enviaremos um link para redefinir sua senha</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh não!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Sucesso!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="requestPass()" #requestPassForm="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">Insira o seu endereço de e-mail:</label>
    <input nbInput
           [(ngModel)]="user.email"
           #email="ngModel"
           id="input-email"
           name="email"
           pattern=".+@.+\..+"
           placeholder="Endereço de e-mail"
           autofocus
           fullWidth
           fieldSize="large"
           [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.email.required')"
           [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        O e-mail é obrigatório!
      </p>
      <p class="caption status-danger" *ngIf="email.errors?.pattern">
        O e-mail deve ser o verdadeiro!
      </p>
    </ng-container>
  </div>

  <button nbButton
          fullWidth
          status="primary"
          size="large"
          [disabled]="submitted || !requestPassForm.valid"
          [class.btn-pulse]="submitted">
    Pedir a senha
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="/auth/login">Volte ao login</a></p>
  <p><a routerLink="/auth/register" class="text-link">Registrar</a></p>
</section>
