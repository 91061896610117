import { Inject, Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NbAuthService } from '../auth.service';
import { switchMap } from 'rxjs/operators';
import { NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '../../auth.options';

@Injectable()
export class NbAuthJWTInterceptor implements HttpInterceptor {
  private injector;
  protected filter: any;

  constructor(injector: Injector, @Inject(NB_AUTH_TOKEN_INTERCEPTOR_FILTER) filter: any) {
    this.injector = injector;
      this.filter = filter;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.filter(req)) {
      return this.authService.isAuthenticatedOrRefresh()
        .pipe(switchMap(authenticated => {
          if (authenticated) {
            return this.authService.getToken().pipe(switchMap((token) => {
              const JWT = `Bearer ${token.getValue()}`;
              req = req.clone({
                setHeaders: {
                  Authorization: JWT,
                },
              });
              return next.handle(req);
            }));
          } else {
            // Request is sent to server without authentication so that the client code
            // receives the 401/403 error and can act as desired ('session expired', redirect to login, aso)
            return next.handle(req);
          }
        }));
    } else {
      return next.handle(req);
    }
  }

  protected get authService(): NbAuthService {
    return this.injector.get(NbAuthService);
  }
}
