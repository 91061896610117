import { Inject, Injectable, InjectionToken } from '@angular/core';
import { nbAuthCreateToken, NbAuthToken, NbAuthTokenClass } from './token';
import { NB_AUTH_TOKENS } from '../../auth.options';
export const NB_AUTH_FALLBACK_TOKEN = new InjectionToken('Nebular Auth Options');

export interface NbTokenPack {
  name: string;
  ownerStrategyName: string;
  createdAt: Number;
  value: string;
}

/**
 * Creates a token parcel which could be stored/restored
 */
@Injectable()
export class NbAuthTokenParceler {
  private fallbackClass;
  private tokenClasses;

  constructor(@Inject(NB_AUTH_FALLBACK_TOKEN) fallbackClass, @Inject(NB_AUTH_TOKENS) tokenClasses) {
    this.fallbackClass = fallbackClass;
    this.tokenClasses = tokenClasses;
  }

  wrap(token: NbAuthToken): string {
    return JSON.stringify({
      name: token.getName(),
      ownerStrategyName: token.getOwnerStrategyName(),
      createdAt: token.getCreatedAt().getTime(),
      value: token.toString(),
    });
  }

  unwrap(value: string): NbAuthToken {
    let tokenClass = this.fallbackClass;
    let tokenValue = '';
    let tokenOwnerStrategyName = '';
    let tokenCreatedAt = null;
    const tokenPack = this.parseTokenPack(value);
    if (tokenPack) {
      tokenClass = this.getClassByName(tokenPack.name) || this.fallbackClass;
      tokenValue = tokenPack.value;
      tokenOwnerStrategyName = tokenPack.ownerStrategyName;
      tokenCreatedAt = new Date(Number(tokenPack.createdAt));
    }
    return nbAuthCreateToken(tokenClass, tokenValue, tokenOwnerStrategyName, tokenCreatedAt);
  }

  protected getClassByName(name: any): NbAuthTokenClass {
    return this.tokenClasses.find((tokenClass) => tokenClass.NAME === name);
  }

  protected parseTokenPack(value: any): NbTokenPack {
    try {
      return JSON.parse(value);
    } catch (e) { }
    return null;
  }
}
