import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of as observableOf } from 'rxjs';
import { NbAuthStrategy } from '../auth-strategy';
import { NbAuthResult } from '../../services/auth-result';
import { NbDummyAuthStrategyOptions } from './dummy-strategy-options';
import { NbAuthStrategyClass } from '../../auth.options';
import { delay } from 'rxjs/operators';
import { dummyStrategyOptions } from './dummy-strategy-options';

@Injectable()
export class NbDummyAuthStrategy extends NbAuthStrategy {
  protected defaultOptions: NbDummyAuthStrategyOptions;

  protected authorizedUsers: any = [
    { email: 'admin@hiveplace.com.br', password: '12345', type: 'provider', name: 'Luiz Silva', occupation: 'Senior Developer', picture: 'assets/images/alan.png' },
    { email: 'homologacao@hiveplace.com.br', password: 'h353YA,+w*Fwg2=', type: 'provider', name: 'Homologação', occupation: 'Tester', picture: 'assets/images/alan.png' },
  ];

  constructor() {
    super();
    this.defaultOptions = dummyStrategyOptions;
  }

  static setup(options: NbDummyAuthStrategyOptions): [NbAuthStrategyClass, NbDummyAuthStrategyOptions] {
    return [NbDummyAuthStrategy, options];
  }

  authenticate(data?: any): Observable<NbAuthResult> {
    return observableOf(this.createDummyResult(data))
      .pipe(delay(this.getOption('delay')));
  }

  register(data?: any): Observable<NbAuthResult> {
    return observableOf(this.createDummyResult(data))
      .pipe(delay(this.getOption('delay')));
  }

  requestPassword(data?: any): Observable<NbAuthResult> {
    return observableOf(this.createDummyResult(data))
      .pipe(delay(this.getOption('delay')));
  }

  resetPassword(data?: any): Observable<NbAuthResult> {
    return observableOf(this.createDummyResult(data))
      .pipe(delay(this.getOption('delay')));
  }

  logout(data?: any): Observable<NbAuthResult> {
    return observableOf(this.createDummyResult(data))
      .pipe(delay(this.getOption('delay')));
  }

  refreshToken(data?: any): Observable<NbAuthResult> {
    return observableOf(this.createDummyResult(data))
      .pipe(delay(this.getOption('delay')));
  }

  protected createDummyResult(data?: any): NbAuthResult {
    if (this.getOption('alwaysFail')) {
      return new NbAuthResult(false, this.createFailResponse(data), null, ['Algo deu errado.']);
    }

    try {
      const validUser = this.authorizedUsers.find((u) => u.email === data.email && u.password === data.password);

      if (!validUser) {
        throw new Error('Usuário ou senha incorretos');
      }

      const token = this.createToken(validUser, true);
      return new NbAuthResult(true, this.createSuccessResponse(validUser), '/', [], ['Conectado com sucesso.'], token);
    } catch (err) {
      return new NbAuthResult(false, this.createFailResponse(data), null, [err.message]);
    }
  }
}
