<nb-tabset fullWidth>
  <nb-tab tabTitle="Ativos">
    <list-tab-item-consumers-providers
      [listItems]=listActives
      [actionItem]="actionItem"
    ></list-tab-item-consumers-providers>
  </nb-tab>
  <nb-tab tabTitle="Pendentes">
    <list-tab-item-consumers-providers
      [listItems]="listPending"
      [actionItem]="actionItem"
    ></list-tab-item-consumers-providers>
  </nb-tab>
  <nb-tab tabTitle="Rejeitados">
    <list-tab-item-consumers-providers
      [listItems]="listRejected"
      [actionItem]="actionItem"
    ></list-tab-item-consumers-providers>
  </nb-tab>
</nb-tabset>
