<nb-card (click)="onDownload()">
  <nb-card-body>
    <img class="type-cert" [src]="iconType" alt="">
    <div class="content-logo" [class]="type">
      <img class="logo-cert" [src]="logo" alt="">
    </div>
    <h2>
      {{ title }}
    </h2>
  </nb-card-body>
</nb-card>
