import {OnInit, Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';

export interface UserData {
  NOME: string;
  CPF: string;
  MCI: string;
}

@Component({
  selector: 'hive-list-endusers',
  styleUrls: ['list-endusers.component.scss'],
  templateUrl: './list-endusers.component.html',
})

export class ListEndUsersComponent implements OnInit {
  @Input() listUsers: any[];
  @Input() actionItem: any;

  settings = {
    editable: false,
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      MCI: {
        type: 'string',
        filter: false
      },
      NOME: {
        type: 'string',
        filter: false
      },
      CPF: {
        type: 'string',
        filter: false
      }
    },
  };

  source: LocalDataSource = new LocalDataSource();

  constructor(private router: Router) {}

  ngOnInit() {
    this.source.load(this.listUsers);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    const filter = filterValue.trim().toLowerCase();

    if (filter.length) {
      this.source.setFilter([{ field: 'CPF', search: filter }, { field: 'NOME', search: filter }, { field: 'MCI', search: filter }], false);
    } else {
      this.source.reset();
    }
  }
  onActionOpen(data): void {
    this.router.navigateByUrl('/pages/detail-end-users/' + data.data.CPF);
  }
}
