/**
 * @license
 * Copyright HIVEPlace. All Rights Reserved.
 * License private
 */
import { NbAuthStrategyOptions, NbStrategyToken } from '../auth-strategy-options';
import { NbAuthSimpleToken } from '../../services/token/token';

export class NbDummyAuthStrategyOptions extends NbAuthStrategyOptions {
  token?: NbStrategyToken;
  delay?: number;
  alwaysFail?: boolean;

  constructor() {
    super();
    this.token = {
      class: NbAuthSimpleToken,
    };
    this.delay = 700;
    this.alwaysFail = false;
  }
}
export const dummyStrategyOptions: NbDummyAuthStrategyOptions = new NbDummyAuthStrategyOptions();
