import { AbstractControl } from '@angular/forms';
import { replaceAll } from './index';

export class Validations {

  /**
   * validate cpf
   * @param {string} cpf
   * @returns {boolean}
   */
  static validateCPF (controle: AbstractControl) {
    const cpf = controle.value;

    let soma: number = 0;
    let resto: number;
    let valido: boolean;

    const regex = new RegExp('[0-9]{11}');

    if (
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999' ||
      !regex.test(cpf)
    ) return { cpfInvalid: true };

    for (let i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10), 10)) valido = false;

    soma = 0;
    for (let i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11), 10)) valido = false;
    valido = true;

    if (valido) return null;

    return { cpfInvalid: true };
  }

  /**
   * validate cnpj
   * @param {string} s
   * @returns {boolean}
   */
  static validateCNPJ (s) {
    const cnpj = s.replace(/[^\d]+/g, '');
    // Valida a quantidade de caracteres
    if (cnpj.length !== 14) return false;
    // Elimina inválidos com todos os caracteres iguais
    if (/^(\d)\1+$/.test(cnpj)) return false;
    // Cáculo de validação
    const t = cnpj.length - 2;
    const d = cnpj.substring(t);
    const d1 = parseInt(d.charAt(0), 10);
    const d2 = parseInt(d.charAt(1), 10);
    const calc = x => {
      const n = cnpj.substring(0, x);
      let y = x - 7;
      let s = 0;
      let r = 0;
      for (let i = x; i >= 1; i--) {
        s += n.charAt(x - i) * y--;
        if (y < 2) y = 9;
      }

      r = 11 - s % 11;
      return r > 9 ? 0 : r;
    };
    return (calc(t) === d1 && calc(t + 1)) === d2;
  }

  /**
   * validate cnpj or cpf
   * @param {string} text
   * @returns {boolean}
   */
  static validateCNPJorCPF (text) {
    // if (validateCPF(text) && (text.length <= 11)) return validateCPF(text)
    // if (validateCNPJ(text)) return validateCNPJ(text)
    return false;
  }

  /**
   * validate email
   * @param {string} email
   * @returns {boolean}
   */
  static validateEmail (email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  /**
   * validate password
   * @param {string} pass
   * @returns {boolean}
   */
  static validPassword (pass) {
    return (pass.length >= 6);
  }

  /**
   * @param {string} path
   * @returns {Boolean}
   */
  static isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path);
  }

  /**
   * @param {string} path
   * @returns {Boolean}
   */
  static comparePassword(controle: AbstractControl) {
    const password = controle.get('password').value;
    const confirmPassword = controle.get('confirmPassword').value;

    if (password === confirmPassword) return null;

    controle.get('confirmPassword').setErrors({ passwordsNoMatch: true });
  }
}
