import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'list-tab-item-consumers-providers',
  styleUrls: ['list-tab-item-cosumers-providers.component.scss'],
  templateUrl: './list-tab-item-cosumers-providers.component.html',
})
export class ListTabItemConsumersProvidersComponent implements OnInit {

  @Input() listItems: any[];
  @Input() actionItem: any;

  constructor() {
  }
  
  ngOnInit() {
    if (!this.listItems) this.listItems = []
  }
}
