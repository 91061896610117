/**
 * @license
 * Copyright HIVEPlace. All Rights Reserved.
 * License private
 */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS, NbAuthSocialLink } from '../../auth.options';
import { getDeepFromObject } from '../../helpers';
import { NbAuthService } from '../../services/auth.service';

@Component({
  selector: 'nb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NbLoginComponent {
    protected service: NbAuthService;
    protected options: {};
    protected cd: ChangeDetectorRef;
    protected router: Router;
    redirectDelay: number;
    showMessages: any;
    strategy: string;
    errors: string[];
    messages: string[];
    user: any;
    submitted: boolean;
    socialLinks: NbAuthSocialLink[];
    rememberMe: boolean;

    constructor(service: NbAuthService, @Inject(NB_AUTH_OPTIONS) options: {}, cd: ChangeDetectorRef, router: Router) {
      this.service = service;
      this.options = options;
      this.cd = cd;
      this.router = router;
      this.redirectDelay = 0;
      this.showMessages = {};
      this.strategy = '';
      this.errors = [];
      this.messages = [];
      this.user = {};
      this.submitted = false;
      this.socialLinks = [];
      this.rememberMe = false;
      this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
      this.showMessages = this.getConfigValue('forms.login.showMessages');
      this.strategy = this.getConfigValue('forms.login.strategy');
      this.socialLinks = this.getConfigValue('forms.login.socialLinks');
      this.rememberMe = this.getConfigValue('forms.login.rememberMe');
    }

    login(): void {
      this.errors = [];
      this.messages = [];
      this.submitted = true;
      this.service.authenticate(this.strategy, this.user).subscribe((result) => {
        this.submitted = false;
        if (result.isSuccess()) {
          this.messages = result.getMessages();
        } else {
          this.errors = result.getErrors();
        }
        const redirect = result.getRedirect();
        if (redirect) {
          return this.router.navigateByUrl('/pages');
        }
        this.cd.detectChanges();
      });
    }

    getConfigValue(key: string): any {
      return getDeepFromObject(this.options, key, null);
    }
}

