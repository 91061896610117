import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hive-card-certificate',
  styleUrls: ['./card-certificate.component.scss'],
  templateUrl: './card-certificate.component.html',
})
export class CardCertificateComponent implements OnInit {
  @Input() link: string = '';
  @Input() title: string = '';
  @Input() logo: any = '';
  @Input() type: any = 'medal';


  iconType = 'assets/images/certificates/medal-icon.png';

  constructor() {
  }

  ngOnInit(): void {
    if (this.type === 'key') {
      this.iconType = 'assets/images/certificates/key-icon.png';
    }
  }

  onDownload() {
    if (this.link) window.open(this.link)
  }
}
