/**
 * @license
 * Copyright HIVEPlace. All Rights Reserved.
 * License private
 */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS } from '../../auth.options';
import { getDeepFromObject } from '../../helpers';
import { NbAuthService } from '../../services/auth.service';

@Component({
  selector: 'nb-reset-password-page',
  styleUrls: ['./reset-password.component.scss'],
  templateUrl: './reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NbResetPasswordComponent {
    protected service: NbAuthService;
    protected options: {};
    protected cd: ChangeDetectorRef;
    protected router: Router;
    redirectDelay: number;
    showMessages: any;
    strategy: string;
    submitted: boolean;
    errors: string[];
    messages: string[];
    user: any;

    constructor(service: NbAuthService, @Inject(NB_AUTH_OPTIONS) options: {}, cd: ChangeDetectorRef, router: Router) {
      this.service = service;
      this.options = options;
      this.cd = cd;
      this.router = router;
      this.redirectDelay = 0;
      this.showMessages = {};
      this.strategy = '';
      this.submitted = false;
      this.errors = [];
      this.messages = [];
      this.user = {};
      this.redirectDelay = this.getConfigValue('forms.resetPassword.redirectDelay');
      this.showMessages = this.getConfigValue('forms.resetPassword.showMessages');
      this.strategy = this.getConfigValue('forms.resetPassword.strategy');
    }

    resetPass(): void {
      this.errors = this.messages = [];
      this.submitted = true;
      this.service.resetPassword(this.strategy, this.user).subscribe((result) => {
          this.submitted = false;
          if (result.isSuccess()) {
              this.messages = result.getMessages();
          } else {
              this.errors = result.getErrors();
          }
          const redirect = result.getRedirect();
          if (redirect) {
              setTimeout(() => {
                  return this.router.navigateByUrl(redirect);
              }, this.redirectDelay);
          }
          this.cd.detectChanges();
      });
  }

  getConfigValue(key): any {
      return getDeepFromObject(this.options, key, null);
  }
}
