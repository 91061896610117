import { Component, OnInit } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NbSidebarService } from '@nebular/theme';

import { NbAuthJWTToken, NbAuthService } from '../../../auth';


@Component({
  selector: 'hive-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  templateUrl: './one-column.layout.html',
})



export class OneColumnLayoutComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();

  user: {
    name: string,
    occupation: string,
    picture: string,
    type: string,
  };

  currentTheme = 'default';

  collapseMenu: boolean = false;

  collapse: boolean = false;

  constructor(private themeService: NbThemeService,
    private authService: NbAuthService,
    private sidebarService: NbSidebarService,
  ) {

    this.sidebarService.onCompact().subscribe(data => {
      const element = document.querySelector('.menu-sidebar');
      if (element) this.collapse = element.classList.contains('compacted');
      this.collapseMenu = !this.collapse;
    });
    this.sidebarService.onToggle().subscribe(data => {
      const element = document.querySelector('.menu-sidebar');
      if (element) this.collapse = element.classList.contains('compacted');
      this.collapseMenu = !this.collapse;
    });
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
        }
      });
  }

  changeTheme(value) {
    const newTheme: string = this.currentTheme === 'default' ? 'dark' : 'default';
    this.themeService.changeTheme(newTheme);
  }
}
