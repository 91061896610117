<nb-card>
  <nb-card-body>
    <nb-auth-block>
      <h1 id="title" class="title">HIVEPlace</h1>
      <p class="sub-title">Olá! Faça login com seu e-mail.</p>

      <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
        <p class="alert-title"><b>Oh não!</b></p>
        <ul class="alert-message-list">
          <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
        </ul>
      </nb-alert>

      <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
        <p class="alert-title"><b>Sucesso!</b></p>
        <ul class="alert-message-list">
          <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
        </ul>
      </nb-alert>

      <form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

        <div class="form-control-group">
          <label class="label" for="input-email">Endereço de e-mail:</label>
          <input nbInput
                fullWidth
                [(ngModel)]="user.email"
                #email="ngModel"
                name="email"
                id="input-email"
                pattern=".+@.+\..+"
                placeholder="E-mail"
                fieldSize="large"
                autofocus
                [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
                [required]="getConfigValue('forms.validation.email.required')"
                [attr.aria-invalid]="email.invalid && email.touched ? true : null">
          <ng-container *ngIf="email.invalid && email.touched">
            <p class="caption status-danger" *ngIf="email.errors?.required">
              O e-mail é obrigatório!
            </p>
            <p class="caption status-danger" *ngIf="email.errors?.pattern">
              O e-mail deve ser o verdadeiro!
            </p>
          </ng-container>
        </div>

        <div class="form-control-group">
          <span class="label-with-link">
            <label class="label" for="input-password">Senha:</label>
            <a class="forgot-password caption-2" routerLink="/auth/request-password">Esqueceu a senha?</a>
          </span>
          <input nbInput
                fullWidth
                [(ngModel)]="user.password"
                #password="ngModel"
                name="password"
                type="password"
                id="input-password"
                placeholder="Senha"
                fieldSize="large"
                [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
                [required]="getConfigValue('forms.validation.password.required')"
                [minlength]="getConfigValue('forms.validation.password.minLength')"
                [maxlength]="getConfigValue('forms.validation.password.maxLength')"
                [attr.aria-invalid]="password.invalid && password.touched ? true : null">
          <ng-container *ngIf="password.invalid && password.touched ">
            <p class="caption status-danger" *ngIf="password.errors?.required">
              A senha é obrigatória!
            </p>
            <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
              A senha deve conter
              de {{ getConfigValue('forms.validation.password.minLength') }}
              a {{ getConfigValue('forms.validation.password.maxLength') }}
              caracteres!
            </p>
          </ng-container>
        </div>

        <div class="form-control-group accept-group">
          <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">Lembre de mim</nb-checkbox>
        </div>

        <button nbButton
                fullWidth
                status="primary"
                size="large"
                [disabled]="submitted || !form.valid"
                [class.btn-pulse]="submitted">
          Log In
        </button>
      </form>

      <section class="another-action" aria-label="Register">
        <!-- Não tem conta? <a class="text-link" routerLink="register">Registre-se</a> -->
        Não tem conta? <a class="text-link" [routerLink]="['/auth/register']">Registre-se</a>
      </section>

    </nb-auth-block>
  </nb-card-body>
</nb-card>
