import { Component, Input } from '@angular/core';

@Component({
  selector: 'hive-card-certificate-toggle',
  styleUrls: ['./card-certificate-toggle.component.scss'],
  templateUrl: './card-certificate-toggle.component.html',
})
export class CardCertificateToggleComponent {

  @Input() name: string = '';
  @Input() logo: string = '';
  @Input() description: string = '';

  constructor() {
  }
}
