import { Injectable } from '@angular/core';
import { NbAuthToken } from './token';
import { NbAuthTokenParceler } from './token-parceler';

export abstract class NbTokenStorage {
  abstract get(): NbAuthToken;
  abstract set(token: NbAuthToken): any;
  abstract clear(): any;
}

@Injectable()
export class NbTokenLocalStorage extends NbTokenStorage {
  private parceler;
  protected key: string;

  constructor(parceler: NbAuthTokenParceler) {
    super();
    this.parceler = parceler;
    this.key = 'auth_app_token';
  }
  /**
   * Returns token from localStorage
   * @returns {NbAuthToken}
   */
  get(): NbAuthToken {
    const raw = localStorage.getItem(this.key);
    return this.parceler.unwrap(raw);
  }

  /**
   * Sets token to localStorage
   * @param {NbAuthToken} token
   */
  set(token: NbAuthToken): void {
    const raw = this.parceler.wrap(token);
    localStorage.setItem(this.key, raw);
  }

  /**
   * Clears token from localStorage
   */
  clear(): void {
    localStorage.removeItem(this.key);
  }
}
