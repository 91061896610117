<!--
<h1 id="title" class="title">Cadastro</h1>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh, não!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Sucesso!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="register()" #form="ngForm" aria-labelledby="title">
  <div class="row">
    <div class="col-md-6">
      <div class="form-control-group">
        <label class="label" for="input-name">Nome completo:</label>
        <input
          nbInput
          [(ngModel)]="user.fullName"
          #fullName="ngModel"
          id="input-name"
          name="fullName"
          placeholder="Nome completo"
          autofocus
          fullWidth
          fieldSize="large"
          [status]="fullName.dirty ? (fullName.invalid  ? 'danger' : 'success') : 'basic'"
          [required]="getConfigValue('forms.validation.fullName.required')"
          [minlength]="getConfigValue('forms.validation.fullName.minLength')"
          [maxlength]="getConfigValue('forms.validation.fullName.maxLength')"
          [attr.aria-invalid]="fullName.invalid && fullName.touched ? true : null"
        >
        <ng-container *ngIf="fullName.invalid && fullName.touched">
          <p class="caption status-danger" *ngIf="fullName.errors?.required">
            O nome completo é obrigatório!
          </p>
          <p class="caption status-danger" *ngIf="fullName.errors?.minlength || fullName.errors?.maxlength">
            O nome completo deve conter
            entre {{getConfigValue('forms.validation.fullName.minLength')}}
            a {{getConfigValue('forms.validation.fullName.maxLength')}}
            caracteres
          </p>
        </ng-container>
      </div>
      <div class="form-control-group">
        <label class="label" for="input-cpf">CPF:</label>
        <input nbInput
              [(ngModel)]="user.cpf"
              mask="000.000.000-99"
              #cpf="ngModel"
              id="input-cpf"
              name="CPF"
              placeholder="Digite o seu CPF"
              fullWidth
              fieldSize="large"
              [status]="cpf.dirty ? (cpf.invalid  ? 'danger' : 'success') : 'basic'"
              [required]="getConfigValue('forms.validation.cpf.required')"
              [attr.aria-invalid]="cpf.invalid && cpf.touched ? true : null">
        <ng-container *ngIf="cpf.invalid && cpf.touched">
          <p class="caption status-danger" *ngIf="cpf.errors?.required">
            O CPF é obrigatório!
          </p>
          <p class="caption status-danger" *ngIf="cpf.errors?.pattern">
            O CPF deve ser verdadeiro!
          </p>
        </ng-container>
      </div>
      <div class="form-control-group">
        <label class="label" for="input-email">Endereço de e-mail:</label>
        <input nbInput
              [(ngModel)]="user.email"
              #email="ngModel"
              id="input-email"
              name="email"
              pattern=".+@.+..+"
              placeholder="Digite seu email"
              fullWidth
              fieldSize="large"
              [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
              [required]="getConfigValue('forms.validation.email.required')"
              [attr.aria-invalid]="email.invalid && email.touched ? true : null">
        <ng-container *ngIf="email.invalid && email.touched">
          <p class="caption status-danger" *ngIf="email.errors?.required">
            O email é obrigatório!
          </p>
          <p class="caption status-danger" *ngIf="email.errors?.pattern">
            O e-mail deve ser o verdadeiro!
          </p>
        </ng-container>
      </div>
      <div class="form-control-group">
        <label class="label" for="input-password">Nome de usuário:</label>
        <input nbInput
              [(ngModel)]="user.userNameAdmin"
              #userNameAdmin="ngModel"
              id="input-userNameAdmin"
              name="userNameAdmin"
              placeholder="Informe o nome de usuário"
              fullWidth
              fieldSize="large"
              [status]="userNameAdmin.dirty ? (userNameAdmin.invalid  ? 'danger' : 'success') : 'basic'"
              [required]="getConfigValue('forms.validation.userNameAdmin.required')"
              [minlength]="getConfigValue('forms.validation.userNameAdmin.minLength')"
              [maxlength]="getConfigValue('forms.validation.userNameAdmin.maxLength')"
              [attr.aria-invalid]="userNameAdmin.invalid && userNameAdmin.touched ? true : null">
        <ng-container *ngIf="userNameAdmin.invalid && userNameAdmin.touched">
          <p class="caption status-danger" *ngIf="userNameAdmin.errors?.required">
            O nome de usuário é obrigatório!
          </p>
        </ng-container>
      </div>
      <div class="form-control-group">
        <label class="label" for="input-password">Senha:</label>
        <input nbInput
              [(ngModel)]="user.password"
              #password="ngModel"
              type="password"
              id="input-password"
              name="password"
              placeholder="Mínimo 8 carateres (deve conter pelo menos 3 números)"
              fullWidth
              fieldSize="large"
              [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
              [required]="getConfigValue('forms.validation.password.required')"
              [minlength]="getConfigValue('forms.validation.password.minLength')"
              [maxlength]="getConfigValue('forms.validation.password.maxLength')"
              [attr.aria-invalid]="password.invalid && password.touched ? true : null">
        <ng-container *ngIf="password.invalid && password.touched">
          <p class="caption status-danger" *ngIf="password.errors?.required">
            Senha requerida!
          </p>
          <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
            A senha deve conter
            entre {{ getConfigValue('forms.validation.password.minLength') }}
            a {{ getConfigValue('forms.validation.password.maxLength') }}
            caracteres
          </p>
        </ng-container>
      </div>
      <div class="form-control-group">
        <label class="label" for="input-re-password">Repita a senha:</label>
        <input nbInput
              [(ngModel)]="user.confirmPassword"
              #rePass="ngModel"
              type="password"
              id="input-re-password"
              name="rePass"
              placeholder="Confirmar a senha"
              fullWidth
              fieldSize="large"
              [status]="rePass.dirty ? (rePass.invalid || password.value != rePass.value  ? 'danger' : 'success') : 'basic'"
              [required]="getConfigValue('forms.validation.password.required')"
              [attr.aria-invalid]="rePass.invalid && rePass.touched ? true : null">
        <ng-container *ngIf="rePass.invalid && rePass.touched">
          <p class="caption status-danger" *ngIf="rePass.errors?.required">
            A confirmação da senha é necessária!
          </p>
          <p class="caption status-danger" *ngIf="password.value != rePass.value && !rePass.errors?.required">
            A senha não corresponde à senha de confirmação.
          </p>
        </ng-container>
      </div>
      <div class="form-control-group">
        <label class="label" for="input-name">Seguimento:</label>
        <nb-select
          placeholder="Seguimento"
          [(ngModel)]="user.idSegment"
          #idSegment="ngModel"
          id="input-idSegment"
          name="idSegment"
          autofocus
          fullWidth
          size="large"
          [status]="idSegment.dirty ? (idSegment.invalid  ? 'danger' : 'success') : 'basic'"
          [required]="getConfigValue('forms.validation.idSegment.required')"
        >
          <nb-option value="1">Option 1</nb-option>
          <nb-option value="2">Option 2</nb-option>
          <nb-option value="3">Option 3</nb-option>
          <nb-option value="4">Option 4</nb-option>
        </nb-select>
        <ng-container *ngIf="idSegment.invalid && idSegment.touched">
          <p class="caption status-danger" *ngIf="idSegment.errors?.required">
            O seguimento é obrigatório!
          </p>
        </ng-container>
      </div>
      <div class="form-control-group accept-group" *ngIf="getConfigValue('forms.register.terms')">
        <nb-checkbox name="terms" [(ngModel)]="user.terms" [required]="getConfigValue('forms.register.terms')">
          Concordo com os <a href="#" target="_blank"><strong>Termos e Condições</strong></a>
        </nb-checkbox>
      </div>
      <button nbButton
              fullWidth
              status="primary"
              size="large"
              [disabled]="submitted || !form.valid"
              [class.btn-pulse]="submitted">
        Criar conta
      </button>
    </div>
    <div class="col-md-6">
      <div class="form-control-group">
        <label class="label" for="input-cdBank">Código do Banco ou Fintech:</label>
        <input nbInput
              [(ngModel)]="user.cdBank"
              mask="00000"
              #cdBank="ngModel"
              id="input-cdBank"
              name="cdBank"
              placeholder="Digite o código do Banco ou Fintech"
              fullWidth
              fieldSize="large"
              [status]="cdBank.dirty ? (cdBank.invalid  ? 'danger' : 'success') : 'basic'"
              [required]="getConfigValue('forms.validation.cdBank.required')"
              [attr.aria-invalid]="cdBank.invalid && cdBank.touched ? true : null">
        <ng-container *ngIf="cdBank.invalid && cdBank.touched">
          <p class="caption status-danger" *ngIf="cdBank.errors?.required">
            O código é obrigatório!
          </p>
        </ng-container>
      </div>
      <div class="form-control-group">
        <label class="label" for="input-cnpj">CNPJ:</label>
        <input nbInput
              [(ngModel)]="user.cnpj"
              mask="00.000.000/0000-99"
              #cnpj="ngModel"
              id="input-cnpj"
              name="cnpj"
              placeholder="Digite o CNPJ da sua empresa"
              fullWidth
              fieldSize="large"
              [status]="cnpj.dirty ? (cnpj.invalid  ? 'danger' : 'success') : 'basic'"
              [required]="getConfigValue('forms.validation.cnpj.required')"
              [attr.aria-invalid]="cnpj.invalid && cnpj.touched ? true : null">
        <ng-container *ngIf="cnpj.invalid && cnpj.touched">
          <p class="caption status-danger" *ngIf="cnpj.errors?.required">
            O CNPJ é obrigatório!
          </p>
          <p class="caption status-danger" *ngIf="cnpj.errors?.pattern">
            O CNPJ deve ser o verdadeiro!
          </p>
        </ng-container>
      </div>
      <div class="form-control-group">
        <label class="label" for="input-txTradingName">Nome comercial:</label>
        <input nbInput
              [(ngModel)]="user.txTradingName"
              #txTradingName="ngModel"
              id="input-txTradingName"
              name="txTradingName"
              placeholder="Informe o nome comercial"
              fullWidth
              fieldSize="large"
              [status]="txTradingName.dirty ? (txTradingName.invalid  ? 'danger' : 'success') : 'basic'"
              [required]="getConfigValue('forms.validation.txTradingName.required')"
              [attr.aria-invalid]="txTradingName.invalid && txTradingName.touched ? true : null">
        <ng-container *ngIf="txTradingName.invalid && txTradingName.touched">
          <p class="caption status-danger" *ngIf="txTradingName.errors?.required">
            O nome comercial é obrigatório!
          </p>
        </ng-container>
      </div>
      <div class="form-control-group">
        <label class="label" for="input-txNameOrganisation">Razão social:</label>
        <input nbInput
              [(ngModel)]="user.txNameOrganisation"
              #txNameOrganisation="ngModel"
              id="input-txNameOrganisation"
              name="txNameOrganisation"
              placeholder="Informe a razão social"
              fullWidth
              fieldSize="large"
              [status]="txNameOrganisation.dirty ? (txNameOrganisation.invalid  ? 'danger' : 'success') : 'basic'"
              [required]="getConfigValue('forms.validation.txNameOrganisation.required')"
              [attr.aria-invalid]="txNameOrganisation.invalid && txNameOrganisation.touched ? true : null">
        <ng-container *ngIf="txNameOrganisation.invalid && txNameOrganisation.touched">
          <p class="caption status-danger" *ngIf="txNameOrganisation.errors?.required">
            A razão social é obrigatória!
          </p>
        </ng-container>
      </div>
      <div class="form-control-group">
        <label class="label" for="input-txUrl">URL:</label>
        <input nbInput
                [(ngModel)]="user.txUrl"
                #txUrl="ngModel"
                id="input-txUrl"
                name="txUrl"
                pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                placeholder="Informe a URL de sua fintech ou banco"
                fullWidth
                fieldSize="large"
                [status]="txUrl.dirty ? (txUrl.invalid  ? 'danger' : 'success') : 'basic'"
                [required]="getConfigValue('forms.validation.txUrl.required')"
                [attr.aria-invalid]="txUrl.invalid && txUrl.touched ? true : null">
        <ng-container *ngIf="txUrl.invalid && txUrl.touched">
          <p class="caption status-danger" *ngIf="txUrl.errors?.required">
            A URL é obrigatória!
          </p>
          <p class="caption status-danger" *ngIf="txUrl.errors?.pattern">
            O URL deve ser verdadeira!
          </p>
        </ng-container>
      </div>
      <div class="form-control-group">
        <label class="label" for="input-txUrl">Nome do tópico:</label>
        <input nbInput
                [(ngModel)]="user.txTopicNamePn"
                #txTopicNamePn="ngModel"
                id="input-txTopicNamePn"
                name="txTopicNamePn"
                placeholder="Informe o nome do tópico"
                fullWidth
                fieldSize="large"
                [status]="txTopicNamePn.dirty ? (txTopicNamePn.invalid  ? 'danger' : 'success') : 'basic'"
                [required]="getConfigValue('forms.validation.txTopicNamePn.required')"
                [attr.aria-invalid]="txTopicNamePn.invalid && txTopicNamePn.touched ? true : null">
        <ng-container *ngIf="txTopicNamePn.invalid && txTopicNamePn.touched">
          <p class="caption status-danger" *ngIf="txTopicNamePn.errors?.required">
            o nome do tópico é obrigatória!
          </p>
        </ng-container>
      </div>
    </div>
  </div>
</form>

<section class="another-action" aria-label="Faça o login">
  Já possui uma conta? <a class="text-link" routerLink="/auth/login">Faça o login</a>
</section> -->

<h1 id="title" class="title">Cadastro</h1>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh, não!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Sucesso!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<nb-card>
  <nb-card-body>
    <nb-stepper #stepper>
      <nb-step [stepControl]="firstForm" label="Dados cadastrais">
        <form [formGroup]="firstForm" (ngSubmit)="onFirstSubmit()" class="step-container">
          <div class="row">
            <div class="col-md-6">
              <p class="lorem">
                Informe os seus dados cadastrais para começarmos o nosso cadastro.
              </p>
              <div class="form-control-group">
                <label class="label" for="fullName">Nome completo:</label>
                <input
                  nbInput
                  id="fullName"
                  name="fullName"
                  type="text"
                  formControlName="fullName"
                  placeholder="Nome completo"
                  autofocus
                  fullWidth
                  fieldSize="large"
                  [status]="fullName.dirty ? (fullName.invalid  ? 'danger' : 'success') : 'basic'"
                  [attr.aria-invalid]="fullName.invalid && fullName.touched ? true : null"
                >
                <ng-container *ngIf="fullName.invalid && (fullName.dirty || fullName.touched)">
                  <p class="caption status-danger" *ngIf="fullName.errors?.required">
                    O nome completo é obrigatório!
                  </p>
                  <p class="caption status-danger" *ngIf="fullName.errors?.minlength || fullName.errors?.maxlength">
                    O nome completo deve conter
                    entre {{getConfigValue('forms.validation.fullName.minLength')}}
                    a {{getConfigValue('forms.validation.fullName.maxLength')}}
                    caracteres
                  </p>
                </ng-container>
              </div>
              <div class="form-control-group">
                <label class="label" for="cpf">CPF:</label>
                <input
                  nbInput
                  mask="000.000.000-99"
                  id="cpf"
                  name="cpf"
                  formControlName="cpf"
                  placeholder="Digite o seu CPF"
                  fullWidth
                  fieldSize="large"
                  [status]="cpf.dirty ? (cpf.invalid  ? 'danger' : 'success') : 'basic'"
                  [attr.aria-invalid]="cpf.invalid && cpf.touched ? true : null"
                >
                <ng-container *ngIf="cpf.invalid && cpf.touched">
                  <p class="caption status-danger" *ngIf="cpf.errors?.required">
                    O CPF é obrigatório!
                  </p>
                  <p class="caption status-danger" *ngIf="cpf.errors?.validateCPF">
                    O CPF deve ser verdadeiro!
                  </p>
                </ng-container>
              </div>
              <div class="form-control-group">
                <label class="label" for="email">E-mail:</label>
                <input
                  nbInput
                  id="email"
                  name="email"
                  formControlName="email"
                  pattern=".+@.+..+"
                  placeholder="Digite seu email"
                  fullWidth
                  fieldSize="large"
                  [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
                  [attr.aria-invalid]="email.invalid && email.touched ? true : null"
                >
                <ng-container *ngIf="email.invalid && email.touched">
                  <p class="caption status-danger" *ngIf="email.errors?.required">
                    O email é obrigatório!
                  </p>
                  <p class="caption status-danger" *ngIf="email.errors?.pattern">
                    O e-mail deve ser o verdadeiro!
                  </p>
                </ng-container>
              </div>
              <div class="form-control-group">
                <label class="label" for="userNameAdmin">Nome de usuário:</label>
                <input
                  nbInput
                  id="userNameAdmin"
                  name="userNameAdmin"
                  formControlName="userNameAdmin"
                  placeholder="Informe o nome de usuário"
                  fullWidth
                  fieldSize="large"
                  [status]="userNameAdmin.dirty ? (userNameAdmin.invalid  ? 'danger' : 'success') : 'basic'"
                  [attr.aria-invalid]="userNameAdmin.invalid && userNameAdmin.touched ? true : null"
                >
                <ng-container *ngIf="userNameAdmin.invalid && userNameAdmin.touched">
                  <p class="caption status-danger" *ngIf="userNameAdmin.errors?.required">
                    O nome de usuário é obrigatório!
                  </p>
                </ng-container>
              </div>
              <div class="form-control-group">
                <label class="label" for="password">Senha:</label>
                <input
                  nbInput
                  type="password"
                  id="password"
                  name="password"
                  formControlName="password"
                  placeholder="Mínimo 8 carateres (deve conter pelo menos 3 números)"
                  fullWidth
                  fieldSize="large"
                  [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
                  [attr.aria-invalid]="password.invalid && password.touched ? true : null"
                >
                <ng-container *ngIf="password.invalid && password.touched">
                  <p class="caption status-danger" *ngIf="password.errors?.required">
                    Senha requerida!
                  </p>
                  <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
                    A senha deve conter
                    entre {{ getConfigValue('forms.validation.password.minLength') }}
                    a {{ getConfigValue('forms.validation.password.maxLength') }}
                    caracteres
                  </p>
                </ng-container>
              </div>
              <div class="form-control-group">
                <label class="label" for="confirmPassword">Repita a senha:</label>
                <input
                  nbInput
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  formControlName="confirmPassword"
                  placeholder="Confirmar a senha"
                  fullWidth
                  fieldSize="large"
                  [status]="confirmPassword.dirty ? (confirmPassword.invalid || password.value != confirmPassword.value  ? 'danger' : 'success') : 'basic'"
                  [attr.aria-invalid]="confirmPassword.invalid && confirmPassword.touched ? true : null"
                >
                <ng-container *ngIf="confirmPassword.invalid && confirmPassword.touched">
                  <p class="caption status-danger" *ngIf="confirmPassword.errors?.required">
                    A confirmação da senha é necessária!
                  </p>
                  <p class="caption status-danger" *ngIf="password.value != confirmPassword.value && !confirmPassword.errors?.required">
                    A senha não corresponde à senha de confirmação.
                  </p>
                </ng-container>
              </div>
              <!-- <button nbButton
                      fullWidth
                      status="primary"
                      size="large"
                      [disabled]="submitted || !form.valid"
                      [class.btn-pulse]="submitted">
                Criar conta
              </button> -->
              <button nbButton nbStepperNext [class.btn-pulse]="submitted">
                Próximo
              </button>
            </div>
            <div class="col-md-6">
              <img class="hive-union-bg-left" src="./assets/images/bg-union-hive.png" >
            </div>
          </div>
        </form>
      </nb-step>
      <nb-step [stepControl]="secondForm" label="Dados do Banco ou Fintech">
        <form [formGroup]="secondForm" (ngSubmit)="onSecondSubmit()" class="step-container">
          <div class="row">
            <div class="col-md-6">
              <p class="lorem">
                Informe os dados sobre a instituição da qual deseja cadastrar
              </p>
              <div class="form-control-group">
                <label class="label" for="txNameOrganisation">Razão social:</label>
                <input
                  nbInput
                  id="txNameOrganisation"
                  name="txNameOrganisation"
                  formControlName="txNameOrganisation"
                  placeholder="Informe a razão social"
                  fullWidth
                  fieldSize="large"
                  [status]="(txNameOrganisation.dirty || txNameOrganisation.touched) ? (txNameOrganisation.invalid  ? 'danger' : 'success') : 'basic'"
                  [attr.aria-invalid]="txNameOrganisation.invalid && txNameOrganisation.touched ? true : null"
                >
                <ng-container *ngIf="txNameOrganisation.invalid && txNameOrganisation.touched">
                  <p class="caption status-danger" *ngIf="txNameOrganisation.errors?.required">
                    A razão social é obrigatória!
                  </p>
                </ng-container>
              </div>
              <div class="form-control-group">
                <label class="label" for="cnpj">CNPJ:</label>
                <input
                  nbInput
                  mask="00.000.000/0000-99"
                  id="cnpj"
                  name="cnpj"
                  formControlName="cnpj"
                  placeholder="Digite o CNPJ da sua empresa"
                  fullWidth
                  fieldSize="large"
                  [status]="cnpj.dirty ? (cnpj.invalid  ? 'danger' : 'success') : 'basic'"
                  [attr.aria-invalid]="cnpj.invalid && cnpj.touched ? true : null"
                >
                <ng-container *ngIf="cnpj.invalid && cnpj.touched">
                  <p class="caption status-danger" *ngIf="cnpj.errors?.required">
                    O CNPJ é obrigatório!
                  </p>
                  <p class="caption status-danger" *ngIf="cnpj.errors?.pattern">
                    O CNPJ deve ser o verdadeiro!
                  </p>
                </ng-container>
              </div>
              <div class="form-control-group">
                <label class="label" for="txUrl">URL:</label>
                <input
                  nbInput
                  id="txUrl"
                  name="txUrl"
                  formControlName="txUrl"
                  placeholder="Informe a URL de sua fintech ou banco"
                  fullWidth
                  fieldSize="large"
                  [status]="txUrl.dirty ? (txUrl.invalid  ? 'danger' : 'success') : 'basic'"
                  [attr.aria-invalid]="txUrl.invalid && txUrl.touched ? true : null"
                >
                <ng-container *ngIf="txUrl.invalid && txUrl.touched">
                  <p class="caption status-danger" *ngIf="txUrl.errors?.required">
                    A URL é obrigatória!
                  </p>
                  <p class="caption status-danger" *ngIf="txUrl.errors?.pattern">
                    O URL deve ser verdadeira!
                  </p>
                </ng-container>
              </div>
              <div class="form-control-group">
                <label class="label" for="idSegment">Seguimento:</label>
                <nb-select
                  id="idSegment"
                  name="idSegment"
                  formControlName="idSegment"
                  placeholder="Seguimento"
                  autofocus
                  fullWidth
                  size="large"
                  [status]="idSegment.dirty ? (idSegment.invalid  ? 'danger' : 'success') : 'basic'"
                  [required]="getConfigValue('forms.validation.idSegment.required')"
                >
                  <nb-option value="1">Option 1</nb-option>
                  <nb-option value="2">Option 2</nb-option>
                  <nb-option value="3">Option 3</nb-option>
                  <nb-option value="4">Option 4</nb-option>
                </nb-select>
                <ng-container *ngIf="idSegment.invalid && idSegment.touched">
                  <p class="caption status-danger" *ngIf="idSegment.errors?.required">
                    O seguimento é obrigatório!
                  </p>
                </ng-container>
              </div>
              <button nbButton nbStepperPrevious>Voltar</button>
              <button nbButton nbStepperNext [class.btn-pulse]="submitted">Próximo</button>
            </div>
            <div class="col-md-6">
              <img class="hive-union-bg-left" src="./assets/images/bg-union-hive.png" >
            </div>
          </div>
        </form>
      </nb-step>
      <nb-step [stepControl]="thirdForm" label="Validação">
        <form [formGroup]="thirdForm" (ngSubmit)="onThirdSubmit()" class="step-container">
          <p class="lorem">
            Valide as suas informações
          </p>
          <div class="row">
            <div class="col-md-6">
              <div class="form-control-group accept-group">
                <nb-checkbox
                  name="terms"
                  formControlName="terms"
                  [status]="terms.dirty ? (terms.invalid  ? 'danger' : 'success') : 'basic'"
                >
                  Concordo com os <a href="#" target="_blank"><strong>Termos e Condições</strong></a>
                </nb-checkbox>
                <ng-container *ngIf="terms.invalid">
                  <p class="caption status-danger" *ngIf="terms.errors?.required">
                    Você deve concordar com os termos antes de continuar.
                  </p>
                </ng-container>
              </div>
            </div>
          </div>
          <button nbButton nbStepperPrevious>Voltar</button>
          <button nbButton nbStepperNext>Criar conta</button>
        </form>
      </nb-step>
      <nb-step [stepControl]="thirdForm" [hidden]="true" label="Conclusão">
        <div class="step-container">
          <h3>Pré-cadastro concluído</h3>
          <p class="lorem">Estamos verificando os dados e enviaremos um e-mail assim que estiver tudo certo...</p>
          <!-- <button nbButton (click)="stepper.reset()">Login</button> -->
          <button nbButton>Login</button>
        </div>
      </nb-step>
    </nb-stepper>
  </nb-card-body>
</nb-card>

<section class="another-action" aria-label="Faça o login">
  Já possui uma conta? <a class="text-link" routerLink="/auth/login">Faça o login</a>
</section>
