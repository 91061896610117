import { http } from '../http'
import listProviders from '../store/providers.json'
import listUsers from '../store/users.json'

const fetchAllCPF = (provider) => {
  return http({
    url: `ReturnAllData/${provider}`,
    method: 'GET'
  })
}

const fetchInterests = (cpf) => {
  return http({
    url: `Interests/${cpf}`,
    method: 'GET'
  })
}

const fetchInterestsNovaData = (cpf) => {
  return http({
    url: `ReturnCpf/${cpf}/novavida`,
    method: 'GET'
  })
}

const fetchProvidersUser = (cpf) => {
  return http({
    url: `ReturnCpf/${cpf}/providerenduser`,
    method: 'GET'
  })
}

const fetchProviderDetail = (cpf, provider) => {
  return http({
    url: `ReturnCpf/${cpf}/${provider}`,
    method: 'GET'
  })
}

const fetchAcoesBigDataCorp = (cpf) => {
  return http({
    url: `ReturnBigDataCorpAcoesProcessos/${cpf}`,
    method: 'GET'
  })
}

const fetchMidiaBigDataCorp = (cpf) => {
  return http({
    url: `ReturnBigDataCorpMidia/${cpf}`,
    method: 'GET'
  })
}

const fetchAllAPIs = () => {
  return new Promise((resolve, reject) => {
    resolve(listProviders)
  })
}

const fetchAllProviders = () => {
  return new Promise((resolve, reject) => {
    const data = listProviders.filter((i) => i.type === 'fornecedor')
    resolve(data)
  })
}

const fetchAllConsumers = () => {
  return new Promise((resolve, reject) => {
    const data = listProviders.filter((i) => i.type === 'consumidor')
    resolve(data)
  })
}

const fetchDetail = (id) => {
  return new Promise((resolve, reject) => {
    const data = listProviders.find((i) => i.id == id)
    resolve(data)
  })
}

const fetchAllUsers = () => {
  return http({
    url: `ReturnAllData/providerenduser`,
    method: 'GET'
  })
}

export const allService = {
  fetchAllCPF,
  fetchInterests,
  fetchInterestsNovaData,
  fetchProvidersUser,
  fetchProviderDetail,
  fetchAllAPIs,
  fetchAllProviders,
  fetchAllConsumers,
  fetchDetail,
  fetchAllUsers,
  fetchAcoesBigDataCorp,
  fetchMidiaBigDataCorp
}
