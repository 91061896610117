<nb-layout windowMode>
  <nb-layout-header fixed>
    <hive-header></hive-header>
  </nb-layout-header>

  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
    <nb-card class="logo-provider-card">
      <nb-card-body>
        <nb-user
          shape="rectangle"
          name="Banco do Brasil"
          picture="assets/images/logo-bb.png"
        ></nb-user>
      </nb-card-body>
    </nb-card>

    <nb-user size="large"
      [name]="user.name"
      [title]="user.occupation"
      [picture]="user.picture">
    </nb-user>

    <ng-content select="nb-menu"></ng-content>

    <nb-card class="card-change-theme-menu">
      <nb-card-header>
        <div class="menu-item ng-tns-c118-6 menu-group ng-star-inserted">
          <span class="ng-tns-c118-6 ng-star-inserted">
            <nb-icon icon="settings-outline"></nb-icon>
            <span *ngIf="!collapseMenu">Preferências</span>
          </span>
        </div>
      </nb-card-header>
      <nb-card-body>
        <nb-icon icon="moon-outline"></nb-icon>
        <nb-toggle *ngIf="!collapseMenu" labelPosition="start"
          (checkedChange)="changeTheme($event)">
          Dark Theme
        </nb-toggle>
      </nb-card-body>
    </nb-card>
  </nb-sidebar>

  <nb-layout-column>
    <ng-content select="router-outlet"></ng-content>
  </nb-layout-column>

  <nb-layout-footer fixed>
    <hive-footer></hive-footer>
  </nb-layout-footer>
</nb-layout>
