<nb-card>
  <nb-card-body *ngIf="listItems && listItems?.length > 0">
    <div class="list-itens-informations">
      <div class="header">
        <div class="one"></div>
        <div class="one">Nome</div>
        <div class="two">Usuários</div>
        <div class="three">Transações</div>
        <div class="four">APIs</div>
        <div class="five">Status</div>
      </div>
      <div
        class="body"
        *ngFor="let item of listItems; let i = index"
        (click)="actionItem(item)"
      >
        <div class="one">
          <img *ngIf="item.logo" [src]="'assets/images/logos/' + item.logo" >
        </div>
        <div class="one">{{ item.name }}</div>
        <div class="two">{{ item.users }}</div>
        <div class="three">{{ item.transactions }}</div>
        <div class="four">{{ item.apis }}</div>
        <div class="five">
          <span *ngIf="item.status" class="status" [class]="item.status.toLowerCase()">{{ item.status }}</span>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-body *ngIf="!listItems || !listItems?.length">
    <h1 class="empty-title">
      Sem dados no momento
    </h1>
  </nb-card-body>
</nb-card>