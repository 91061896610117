import { Component, Input } from '@angular/core';

@Component({
  selector: 'list-tab-consumers-providers',
  styleUrls: ['./list-tab-cosumers-providers.component.scss'],
  templateUrl: './list-tab-cosumers-providers.component.html',
})
export class ListTabConsumersProvidersComponent {

  @Input() listActives: object = [];
  @Input() listPending: object = [];
  @Input() listRejected: object = [];
  @Input() actionItem: any;

  constructor() {
  }
}
