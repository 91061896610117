import { Injectable } from '@angular/core';
import { allService } from '../services';

/**
 * Common authentication service.
 * Should be used to as an interlayer between UI Components and Auth Strategy.
 */

 @Injectable()
export class HttpActions {
  constructor() {
  }

  /**
   * Buscar todos os usuário/CPFs por provedor
   */
  async fetchAllCPF(provider) {
    try {
      const data = await allService.fetchAllCPF(provider)
      return data
    } catch (error) {
      return false
    }
  }
  /**
   * Buscar todos as apis
   */
  async fetchAllAPIs() {
    try {
      const data = await allService.fetchAllAPIs()
      return data
    } catch (error) {
      return false
    }
  }
  /**
   * Buscar todos os fornecedores
   */
  async fetchAllProviders() {
    try {
      const data = await allService.fetchAllProviders()
      return data
    } catch (error) {
      console.log(error)
      return false
    }
  }
  /**
   * Buscar todos os consumidores
   */
  async fetchAllConsumers() {
    try {
      const data = await allService.fetchAllConsumers()
      return data
    } catch (error) {
      console.log(error)
      return false
    }
  }
  /**
   * Buscar detalhes de fornecedor ou consumidor
   */
  async fetchDetail(id) {
    try {
      const data = await allService.fetchDetail(id)
      return data
    } catch (error) {
      console.log('error-------')
      console.log(error)
      return false
    }
  }
  /**
   * Buscar todos os usuarios
   */
  async fetchAllUsers() {
    try {
      const { data } = await allService.fetchAllUsers()
      return data
    } catch (error) {
      console.log('error-------')
      console.log(error)
      return false
    }
  }
  /**
   * Buscar interesses de um usuário
   */
  async fetchInterests(cpf) {
    try {
      const { data } = await allService.fetchInterests(cpf)
      return data
    } catch (error) {
      console.log('error-------')
      console.log(error)
      return false
    }
  }
  /**
   * Buscar os interesses novadata
   */
  async fetchInterestsNovaData(cpf) {
    try {
      const { data } = await allService.fetchInterestsNovaData(cpf)
      return data
    } catch (error) {
      console.log('error-------')
      console.log(error)
      return false
    }
  }
  /**
   * Buscar os providers de um usuário
   */
  async fetchProvidersUser(cpf) {
    try {
      const { data } = await allService.fetchProvidersUser(cpf)
      return data
    } catch (error) {
      console.log('error-------')
      console.log(error)
      return false
    }
  }
  /**
   * Buscar os detalhes do provider
   */
  async fetchProviderDetail(cpf, provider) {
    try {
      const { data } = await allService.fetchProviderDetail(cpf, provider)
      return data
    } catch (error) {
      console.log('error-------')
      console.log(error)
      return false
    }
  }
  /**
   * Buscar os detalhes do provider
   */
  async fetchAcoesBigDataCorp(cpf) {
    try {
      const { data } = await allService.fetchAcoesBigDataCorp(cpf)
      return data
    } catch (error) {
      console.log('error-------')
      console.log(error)
      return false
    }
  }
  /**
   * Buscar os detalhes do provider
   */
  async fetchMidiaBigDataCorp(cpf) {
    try {
      const { data } = await allService.fetchMidiaBigDataCorp(cpf)
      return data
    } catch (error) {
      console.log('error-------')
      console.log(error)
      return false
    }
  }
}
