import { Component, Input } from '@angular/core';

@Component({
  selector: 'hive-card-api-monitor',
  styleUrls: ['./card-api-monitor.component.scss'],
  templateUrl: './card-api-monitor.component.html',
})
export class CardApiMonitorComponent {

  @Input() title: string = '';
  @Input() type: string = '';
  @Input() status: string = 'healthy';
  @Input() statusName: string = '';
  @Input() milliseconds: string = '';
  @Input() timeout: string = '0';
  @Input() request: string = '';

  constructor() {
  }
}
