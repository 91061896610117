import { Component, OnInit } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { NbAuthJWTToken, NbAuthService } from '../../../auth';

@Component({
  selector: 'hive-sub-column-layout',
  styleUrls: ['./sub-column.layout.scss'],
  templateUrl: './sub-column.layout.html',
})
export class SubColumnLayoutComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();

  user: {
    name: string,
    occupation: string,
    picture: string,
    type: string,
  };

  currentTheme = 'default';

  constructor(private themeService: NbThemeService,
    private authService: NbAuthService) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
        }
      });
  }

  changeTheme(value) {
    const newTheme: string = this.currentTheme === 'default' ? 'dark' : 'default';
    this.themeService.changeTheme(newTheme);
  }
}
