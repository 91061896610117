/**
 * @license
 * Copyright HIVEPlace. All Rights Reserved.
 * License private
 */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Validations } from '../../../utils/validators';

import { NB_AUTH_OPTIONS } from '../../auth.options';
import { getDeepFromObject } from '../../helpers';
import { NbAuthService } from '../../services/auth.service';

@Component({
  selector: 'nb-register',
  templateUrl: './register.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./register.component.scss'],
})

export class NbRegisterComponent implements OnInit {
    protected service: NbAuthService;
    protected options: {};
    protected cd: ChangeDetectorRef;
    protected router: Router;

    redirectDelay: number;
    showMessages: any;
    strategy: string;
    submitted: boolean;
    errors: string[];
    messages: string[];
    user: any;

    firstForm: FormGroup;
    secondForm: FormGroup;
    thirdForm: FormGroup;

    constructor(
      service: NbAuthService,
      @Inject(NB_AUTH_OPTIONS) options: {},
      cd: ChangeDetectorRef,
      router: Router,
      private fb: FormBuilder,
    ) {
      this.service = service;
      this.options = options;
      this.cd = cd;
      this.router = router;
      this.redirectDelay = 0;
      this.showMessages = {};
      this.strategy = '';
      this.submitted = false;
      this.errors = [];
      this.messages = [];
      this.user = {};
      this.redirectDelay = this.getConfigValue('forms.register.redirectDelay');
      this.showMessages = this.getConfigValue('forms.register.showMessages');
      this.strategy = this.getConfigValue('forms.register.strategy');
    }

    ngOnInit() {
      this.firstForm = this.fb.group({
        fullName: [ '', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(120)]) ],
        cpf: [ '', Validators.compose([Validators.required, Validations.validateCPF]) ],
        email: [ '', Validators.compose([Validators.required, Validators.email]) ],
        userNameAdmin: [ '', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(60)]) ],
        password: [ '', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(120)]) ],
        confirmPassword: [ '', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(120)]) ],
      }, {
        validator: Validations.comparePassword,
      });

      this.secondForm = this.fb.group({
        txNameOrganisation: [ '', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(180)]) ],
        cnpj: [ '', Validators.compose([Validators.required, Validations.validateCPF]) ],
        txUrl: [ '', Validators.compose([Validators.required, Validators.minLength(4), Validators.pattern('[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?')]) ],
        idSegment: [ '', Validators.compose([Validators.required]) ],
      });

      this.thirdForm = this.fb.group({
        terms: ['', Validators.required],
      });
    }

    get fullName() { return this.firstForm.get('fullName'); }
    get cpf() { return this.firstForm.get('cpf'); }
    get email() { return this.firstForm.get('email'); }
    get userNameAdmin() { return this.firstForm.get('userNameAdmin'); }
    get password() { return this.firstForm.get('password'); }
    get confirmPassword() { return this.firstForm.get('confirmPassword'); }

    get txNameOrganisation() { return this.secondForm.get('txNameOrganisation'); }
    get cnpj() { return this.secondForm.get('cnpj'); }
    get txUrl() { return this.secondForm.get('txUrl'); }
    get idSegment() { return this.secondForm.get('idSegment'); }

    get terms() { return this.thirdForm.get('terms'); }

    onFirstSubmit() {
      // console.log(this.firstForm.value)
      this.firstForm.markAsDirty();
    }

    onSecondSubmit() {
      this.secondForm.markAsDirty();
    }

    onThirdSubmit() {
      this.thirdForm.markAsDirty();
    }

    register() {
      this.errors = this.messages = [];
      this.submitted = true;
      console.log('------------');
      console.log(this.user);
      console.log('------------');
      this.service.register(this.strategy, this.user).subscribe((result) => {
        console.log(result);
        // this.submitted = false;
        // if (result.isSuccess()) {
        //   this.messages = result.getMessages();
        // } else {
        //   this.errors = result.getErrors();
        // }
        // const redirect = result.getRedirect();
        // if (redirect) {
        //   setTimeout(() => {
        //     return this.router.navigateByUrl(redirect);
        //   }, this.redirectDelay);
        // }
        // this.cd.detectChanges();
      });
    }

    getConfigValue(key) {
      return getDeepFromObject(this.options, key, null);
    }




}
