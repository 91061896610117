<nb-card
  size="tiny"
  class="hive-api-card-monitor"
  [class]="status"
>
  <nb-card-header>
    <img [src]="logo" alt="">
    <span class="title">{{ name }}</span>
  </nb-card-header>
  <nb-card-body>
    <h1 *ngIf="type !== 'img'">{{ value }}</h1>
    <div *ngIf="type === 'img'" class="img-list">
      <img src="assets/images/logo-inter.png" alt="">
      <img src="assets/images/logo-santander.png" alt="">
      <img src="assets/images/logo-vivipay.png" alt="">
      <img src="assets/images/logo-proben.png" alt="">
    </div>
  </nb-card-body>
  <nb-card-footer *ngIf="monthOneValue && monthTwoValue">
    <div class="col">
      <h4>{{ title1 }}</h4>
      <p>{{ monthOneValue }}</p>
    </div>
    <div class="col">
      <h4>{{ title2 }}</h4>
      <p>{{ monthTwoValue }}</p>
    </div>
  </nb-card-footer>
</nb-card>
