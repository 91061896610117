import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NbAuthResult } from '../services/auth-result';
import { NbAuthStrategyOptions } from './auth-strategy-options';
import { NbAuthToken } from '../services/token/token';
import { deepExtend, getDeepFromObject } from '../helpers';
import { nbAuthCreateToken, NbAuthIllegalTokenError } from '../services/token/token';

export abstract class NbAuthStrategy {
  protected defaultOptions: NbAuthStrategyOptions;
  protected options: NbAuthStrategyOptions;

  setOptions(options: any): void {
    this.options = deepExtend({}, this.defaultOptions, options);
  }

  getOption(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  createToken<T extends NbAuthToken>(value: any, failWhenInvalidToken?: boolean): T {
    const token = nbAuthCreateToken(this.getOption('token.class'), value, this.getName(), null);
    if (failWhenInvalidToken && !token.isValid()) {
      throw new NbAuthIllegalTokenError('Token is empty or invalid.');
    }
    return token;
  }

  getName(): string {
    return this.getOption('name');
  }

  abstract authenticate(data?: any): Observable<NbAuthResult>;
  abstract register(data?: any): Observable<NbAuthResult>;
  abstract requestPassword(data?: any): Observable<NbAuthResult>;
  abstract resetPassword(data?: any): Observable<NbAuthResult>;
  abstract logout(): Observable<NbAuthResult>;
  abstract refreshToken(data?: any): Observable<NbAuthResult>;

  protected createFailResponse(data?: any): HttpResponse<Object> {
    return new HttpResponse({ body: {}, status: 401 });
  }
  protected createSuccessResponse(data?: any): HttpResponse<Object> {
    return new HttpResponse({ body: data, status: 200 });
  }
  protected getActionEndpoint(action: string): string {
    const actionEndpoint = this.getOption(`${action}.endpoint`);
    const baseEndpoint = this.getOption('baseEndpoint');
    return actionEndpoint ? baseEndpoint + actionEndpoint : '';
  }
}
