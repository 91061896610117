<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <img src="assets/images/hive-logo-blue-small.png">
    </a>
    <a class="logo" href="#" (click)="navigateHome()">HIVEPlace</a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action> -->
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    <nb-action class="control-item">
      <a title="Sair" href="/auth/logout">
        Sair
      </a>
    </nb-action>
  </nb-actions>
</div>
