import { Component, Input } from '@angular/core';

@Component({
  selector: 'hive-card-overview',
  styleUrls: ['./card-overview.component.scss'],
  templateUrl: './card-overview.component.html',
})
export class CardOverviewComponent {

  @Input() logo: string = '';
  @Input() name: string = '';
  @Input() type: string = 'text';
  @Input() value: string = '';
  @Input() monthOneValue: string = '';
  @Input() monthTwoValue: string = '';
  @Input() status: string = '';
  @Input() title1: string = 'MONTH1';
  @Input() title2: string = 'MONTH2';

  constructor() {
  }
}
