/**
 * @license
 * Copyright HIVEPlace. All Rights Reserved.
 * License private
 */
import { NbAuthTokenClass } from '../services/token/token';

export interface NbStrategyToken {
  class?: NbAuthTokenClass;
  [key: string]: any;
}

export class NbAuthStrategyOptions {
  name: string;
  token?: NbStrategyToken;
}
