/**
 * @license
 * Copyright HIVEPlace. All Rights Reserved.
 * License private
 */
import { NbAuthTokenClass, NbAuthOAuth2Token } from '../../services/token/token';
import { NbAuthStrategyOptions } from '../auth-strategy-options';

export enum NbOAuth2ResponseType {
  CODE = 'code',
  TOKEN = 'token',
}
export enum NbOAuth2GrantType {
  AUTHORIZATION_CODE = 'authorization_code',
  PASSWORD = 'password',
  REFRESH_TOKEN = 'refresh_token',
}
export enum NbOAuth2ClientAuthMethod {
  NONE = 'none',
  BASIC = 'basic',
  REQUEST_BODY = 'request-body',
}

export class NbOAuth2AuthStrategyOptions extends NbAuthStrategyOptions {
  baseEndpoint?: string;
  clientId: string;
  clientSecret?: string;
  clientAuthMethod?: string;
  redirect?: {
    success?: string;
    failure?: string;
  };
  defaultErrors?: any[];
  defaultMessages?: any[];
  authorize?: {
    endpoint?: string;
    redirectUri?: string;
    responseType?: string;
    requireValidToken?: boolean;
    scope?: string;
    state?: string;
    params?: {
      [key: string]: string;
    };
  };
  token?: {
    endpoint?: string;
    grantType?: string;
    redirectUri?: string;
    scope?: string;
    requireValidToken?: boolean;
    class: NbAuthTokenClass;
  };
  refresh?: {
    endpoint?: string;
    grantType?: string;
    scope?: string;
    requireValidToken?: boolean;
  };

  constructor() {
    super();

    this.baseEndpoint = '';
    this.clientId = '';
    this.clientSecret = '';
    this.clientAuthMethod = NbOAuth2ClientAuthMethod.NONE;
    this.redirect = {
      success: '/',
      failure: null,
    };
    this.defaultErrors = ['Something went wrong, please try again.'];
    this.defaultMessages = ['You have been successfully authenticated.'];
    this.authorize = {
      endpoint: 'authorize',
      responseType: NbOAuth2ResponseType.CODE,
      requireValidToken: true,
    };
    this.token = {
      endpoint: 'token',
      grantType: NbOAuth2GrantType.AUTHORIZATION_CODE,
      requireValidToken: true,
      class: NbAuthOAuth2Token,
    };
    this.refresh = {
      endpoint: 'token',
      grantType: NbOAuth2GrantType.REFRESH_TOKEN,
      requireValidToken: true,
    };
  }
}
export const auth2StrategyOptions: NbOAuth2AuthStrategyOptions = new NbOAuth2AuthStrategyOptions();
