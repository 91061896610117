import axios from 'axios'

const http = axios.create({
  baseURL: 'https://api.poc.hiveplace.com.br/hive/ReturnData',
  timeout: 360000,
  headers: {
    'Content-Type': 'application/json'
  }
})

// request interceptor
http.interceptors.request.use(
  async config => {
    config.headers['Authorization'] = `Basic X3N5c3RlbTpJbnRlckAyMDIw`
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
http.interceptors.response.use(
  response => {
    // Token expired
    if (response.status === 401) {
      return Promise.reject(new Error(response.message || 'Error'))
    }
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export { http }
