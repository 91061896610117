import { Inject, Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NbAuthService } from '../auth.service';
import { switchMap } from 'rxjs/operators';
import { NB_AUTH_INTERCEPTOR_HEADER } from '../../auth.options';

@Injectable()
export class NbAuthSimpleInterceptor implements HttpInterceptor {
  private injector;
  protected headerName: string;

  constructor(injector: Injector, @Inject(NB_AUTH_INTERCEPTOR_HEADER) headerName?: string) {
    this.injector = injector;
    this.headerName = headerName;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getToken()
      .pipe(switchMap((token) => {
        if (token && token.getValue()) {
          req = req.clone({
            setHeaders: {
              [this.headerName]: token.getValue(),
            },
          });
        }
        return next.handle(req);
      }));
  }

  protected get authService(): NbAuthService {
    return this.injector.get(NbAuthService);
  }
}
