<nb-card>
  <nb-card-body *ngIf="listUsers && listUsers?.length > 0">
    <div class="filter-row">
      <nb-form-field>
        <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
        <input type="text" nbInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
      </nb-form-field>
    </div>
    <table class="table-header-titles">
      <thead ng-reflect-is-all-selected="false" class="ng-star-inserted" style="">
        <tr ng2-st-thead-titles-row="" class="ng2-smart-titles ng-star-inserted" ng-reflect-is-all-selected="false">
          <th class="ng2-smart-th MCI ng-star-inserted">
            <a class="ng2-smart-sort-link sort ng-star-inserted"> MCI </a>
          </th>
          <th class="ng2-smart-th NOME ng-star-inserted">
            <a class="ng2-smart-sort-link sort ng-star-inserted"> Nome </a>
          </th>
          <th class="ng2-smart-th CPF ng-star-inserted">
            <a class="ng2-smart-sort-link sort ng-star-inserted"> CPF </a>
          </th>
        </tr>
      </thead>
    </table>
    <ng2-smart-table
      [settings]="settings"
      [source]="source"
      (userRowSelect)="onActionOpen($event)"
    >
    </ng2-smart-table>
  </nb-card-body>
  <nb-card-body *ngIf="!listUsers || !listUsers.length">
    <h1 class="empty-title">
      Sem dados no momento
    </h1>
  </nb-card-body>
</nb-card>