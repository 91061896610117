import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'hive-card-market-api',
  styleUrls: ['./card-market-api.component.scss'],
  templateUrl: './card-market-api.component.html',
})

export class CardMarketApiComponent {
  @Input() client: string = '';
  @Input() service: string = '';
  @Input() value: string = '';
  @Input() logo: any = require('assets/images/logo-bb.png');

  constructor(private _router: Router) {
  }

  openDetail(id): void {
    // this._router.navigate(['pages/marketplace/detail', id]);
    console.log('id: ', id);
  }
}
