import { InjectionToken } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { NbAuthStrategy } from './strategies/auth-strategy';
import { NbAuthStrategyOptions } from './strategies/auth-strategy-options';
import { NbAuthToken, NbAuthTokenClass } from './services/token/token';

export type NbAuthStrategyClass = new (...params: any[]) => NbAuthStrategy;
export type NbAuthStrategies = [NbAuthStrategyClass, NbAuthStrategyOptions][];

export interface NbAuthOptions {
  forms?: any;
  strategies?: NbAuthStrategies;
}

export interface NbAuthSocialLink {
  link?: string;
  url?: string;
  target?: string;
  title?: string;
  icon?: string;
}

const socialLinks = [];
export const defaultAuthOptions: any = {
  strategies: [],
  forms: {
    login: {
      redirectDelay: 500,
      strategy: 'email',
      rememberMe: true,
      showMessages: {
        success: true,
        error: true,
      },
      socialLinks: socialLinks,
    },
    register: {
      redirectDelay: 500,
      strategy: 'email',
      showMessages: {
        success: true,
        error: true,
      },
      terms: true,
      socialLinks: socialLinks,
    },
    requestPassword: {
      redirectDelay: 500,
      strategy: 'email',
      showMessages: {
        success: true,
        error: true,
      },
      socialLinks: socialLinks,
    },
    resetPassword: {
      redirectDelay: 500,
      strategy: 'email',
      showMessages: {
        success: true,
        error: true,
      },
      socialLinks: socialLinks,
    },
    logout: {
      redirectDelay: 500,
      strategy: 'email',
    },
    validation: {
      password: {
        required: true,
        minLength: 4,
        maxLength: 50,
      },
      email: {
        required: true,
      },
      fullName: {
        required: true,
        minLength: 4,
        maxLength: 120,
      },
      cnpj: {
        required: true,
        minLength: 11,
        maxLength: 50,
      },
      cpf: {
        required: true,
        minLength: 11,
        maxLength: 11,
      },
      idSegment: {
        required: true,
        minLength: 11,
        maxLength: 11,
      },
      txTradingName: {
        required: true,
        minLength: 4,
        maxLength: 80,
      },
      txNameOrganisation: {
        required: true,
        minLength: 4,
        maxLength: 80,
      },
      txUrl: {
        required: true,
        minLength: 4,
        maxLength: 120,
      },
      txTopicNamePn: {
        required: true,
        minLength: 1,
        maxLength: 80,
      },
      cdBank: {
        required: true,
        minLength: 1,
        maxLength: 10,
      },
      userNameAdmin: {
        required: true,
        minLength: 1,
        maxLength: 120,
      },
    },
  },
};

export const NB_AUTH_OPTIONS: InjectionToken<NbAuthOptions> = new InjectionToken('Nebular Auth Options');
export const NB_AUTH_USER_OPTIONS: InjectionToken<NbAuthOptions> = new InjectionToken('Nebular User Auth Options');
export const NB_AUTH_STRATEGIES: InjectionToken<NbAuthStrategies> = new InjectionToken('Nebular Auth Strategies');
export const NB_AUTH_TOKENS: InjectionToken<NbAuthTokenClass<NbAuthToken>[]> = new InjectionToken('Nebular Auth Tokens');
export const NB_AUTH_INTERCEPTOR_HEADER: InjectionToken<string> = new InjectionToken('Nebular Simple Interceptor Header');
export const NB_AUTH_TOKEN_INTERCEPTOR_FILTER: InjectionToken<(req: HttpRequest<any>) => boolean> = new InjectionToken('Nebular Interceptor Filter');
